import queryString from 'query-string';

import { API_URL } from '../../environment';
import { ducksActionCreator } from '../ducksHelpers';

export const estimateSettings = {
  stateName: 'estimate',
  dataType: 'object',
  actions: {
    fetch: 'ducks/schedule/FETCH_APPOINTMENT_ESTIMATE',
  },
};

// Action Creators
export function createAppointmentEstimate(
  params,
  callback = () => {},
  errorCallback = () => {},
) {
  return ducksActionCreator(
    estimateSettings.actions.fetch,
    'post',
    `${API_URL}/practice/estimates?${queryString.stringify(params)}`,
    {},
    true,
    callback,
    errorCallback,
  );
}
